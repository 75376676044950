<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("ANALYTICS.MEETING_ROOMS_UTILIZATION_TITLE") }}</h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin: Search Form-->
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-lg-12 col-xl-12">
            <div class="row align-items-center">
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.PERIOD_TITLE") }}</label>
                <treeselect v-model="filter_period"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            @select="setPeriod"
                            :normalizer="normalizer"
                            :disabled="!isChartAvailable"
                            :clearable="false"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="periodList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.OFFICES_TITLE") }}</label>
                <treeselect v-model="filter_offices"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            :normalizer="normalizer"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="officesList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.FLO0RS_TITLE") }}</label>
                <treeselect v-model="filter_floors"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            :normalizer="normalizer"
                            :disabled="!filter_offices"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="office.floors">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.MEETING_ROOMS_TITLE") }}</label>
                <treeselect v-model="filter_meeting_room"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer"
                            :disabled="!filter_floors"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="meetingRoomsList.items">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <!--      ВОЗМОЖНО НУЖНО БУДЕТ УБРАТЬ И УДАЛИТЬ ЗАВИСИМЫЙ КОМПОНЕНТ        -->
              <div class="offset-md-1 col-md-2 my-2 my-md-0">
                <label class="d-none d-md-block">{{ $t("ANALYTICS.WORKING_HOURS_TITLE") }}:</label>
                <b-button :disabled="!isChartAvailable" class="btn btn-button btn-light btn-sm"
                          v-b-modal.modal-edit-working-hours>
                  <span class="svg-icon svg-icon-dark-50"><Settings/></span>
                  <span class="font-weight-bolder">{{ $t("ANALYTICS.SETTINGS_BUTTON") }}</span>
                </b-button>
              </div>
              <!--      ВОЗМОЖНО НУЖНО БУДЕТ УБРАТЬ И УДАЛИТЬ ЗАВИСИМЫЙ КОМПОНЕНТ        -->
            </div>
          </div>
        </div>
      </div>
      <!--end: Search Form-->
      <apexchart v-if="isChartAvailable" type="bar" height="350" :options="categoriesCounter" :series="dateCounter"></apexchart>
      <!--begin::Dummy-->
      <template v-else>
        <div class="row">
          <div class="col-md-6 alert alert-custom alert-light-warning fade show ml-4 my-5" role="alert">
            <div class="alert-icon"><Warning/></div>
            <div class="alert-text text-secondary">{{ $t("ANALYTICS.WARNING_TEXT") }}</div>
          </div>
        </div>
      </template>
      <!--end::Dummy-->
    </div>
    <!--end::Body-->
    <!--begin::Edit Working Hours Modal-->
    <EditWorkingHours
      @changeWorkingHours="changeWorkingHours"/>
    <!--end::Edit Working Hours Modal-->
    <!--begin::Edit Period Date Modal-->
    <EditPeriodDateModal
        @changePeriodDate="changePeriodDate"/>
    <!--end::Edit Period Date Modal-->
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import i18n from "../../../../core/plugins/vue-i18n";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_RESOURCES_UTILIZATION_REPORT } from "@/core/services/store/analytics/utilization.module";
import { GET_OFFICE, GET_OFFICES_LIST } from "@/core/services/store/management.module";
import { GET_MEETING_ROOMS_LIST } from "@/core/services/store/management/resources.module";

import Warning from "@/view/layout/svg/Warning";
import Settings from "@/view/layout/svg/Settings";
import EditWorkingHours from "@/view/layout/modal/EditWorkingHours";
import EditPeriodDateModal from "@/view/layout/modal/EditPeriodDateModal";

export default {
  name: "MeetingRoomsUtilization",
  components: {
    Warning,
    Settings,
    EditWorkingHours,
    EditPeriodDateModal
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("ANALYTICS.MEETING_ROOMS_UTILIZATION_TITLE") }]);
    this.$store.dispatch(GET_OFFICES_LIST);
    // this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters);
  },
  data() {
    return {
      isChartAvailable: false,
      filters: {
        from: moment(new Date()).add(-6, 'days').format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY'),
        type: 'meeting_room',
        workingHours: [
          {
            day: "monday",
            value: 12,
          },
          {
            day: "tuesday",
            value: 12,
          },
          {
            day: "wednesday",
            value: 12,
          },
          {
            day: "thursday",
            value: 12,
          },
          {
            day: "friday",
            value: 12,
          },
          {
            day: "saturday",
            value: 0,
          },
          {
            day: "sunday",
            value: 0,
          }
        ]
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      filter_period: 1,
      filter_offices: null,
      filter_floors: null,
      filter_meeting_room: null,
      optionsDatePicker: {
        locale: i18nService.getActiveLanguage(),
        format: 'DD.MM.YYYY',
        useCurrent: true,
        showClear: false,
        showClose: false,
        maxDate: new Date()
      },
      prevFrom: moment(new Date()).add(-13, 'days').format('DD.MM.YYYY'),
      prevTo: moment(new Date()).add(-7, 'days').format('DD.MM.YYYY')
    }
  },
  watch: {
    filter_offices(val) {
      if (val !== undefined) {
        this.$store.dispatch(GET_OFFICE, val)
      } else {
        this.filter_floors = null;
      }
    },
    filter_floors(val) {
      if (val !== undefined && val !== null) {
        this.isChartAvailable = true;
        this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: val, params: { limit: 1000 } });
        this.filters.floorId = val;
        this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters);
      } else {
        this.isChartAvailable = false;
        this.filter_meeting_room = null;
      }
    },
    filter_meeting_room(val) {
      if (val.length > 0) this.filters.resources = val;
      else delete this.filters.resources;
      this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters);
    }
  },
  computed: {
    ...mapGetters([
      'periodList',
      'resourcesUtilizationSelectedData',
      'resourcesUtilizationPreviousData',
      'officesList',
      'office',
      'meetingRoomsList'
    ]),
    categoriesCounter() {
      const categories = this.resourcesUtilizationSelectedData.map(item => item.date);
      const prevCategories = this.resourcesUtilizationPreviousData.map(item => item.date);
      const categoriesAnnotations = [];
      this.resourcesUtilizationSelectedData.forEach(item => {
        if (item.is_holiday) {
          categoriesAnnotations.push({
            x: item.date,
            strokeDashArray: 9,
            borderColor: '#C1D3E1',
            label: {
              show: true,
              text: this.$t("ANALYTICS.NO_WORK_DAYS"),
              borderWidth: 0,
              style: {
                color: '#9396AB',
                fontSize: '12px',
                fontWeight: 300,
                background: '#EBEFF2'
              }
            }
          })
        }
      })
      return {
          colors: ['#5B5E8B', '#C1D3E1'],
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
          },
          annotations: {
            xaxis: categoriesAnnotations
          },
          xaxis: {
            categories: categories,
            labels: {
              formatter: function (value) {
                return moment(value, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM');
              }
            }
          },
          yaxis: {
            max: 100, // Max value for utilization (%)
            title: {
              text: this.$t("ANALYTICS.MEETING_ROOMS_UTILIZATION_TITLE"),
              style: {
                color: '#9396AB',
                fontSize: '12px',
                fontWeight: 300,
              },
            },
            labels: {
              formatter: function (value) {
                return value + "%";
              }
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              let utilText = i18n.t("ANALYTICS.UTILIZATION_TITLE") + ": ";
              if (seriesIndex === 1) {
                return (
                    '<div style="font-size: 13px">' +
                    '<div style="background-color: #F3F7FF; padding: 10px;">' + moment(prevCategories[dataPointIndex], 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM') + '</div>' +
                    '<div style="padding: 10px">' +
                    '<div style="background-color: #C1D3E1; display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 3px;"></div> ' +
                     utilText + '<strong>' + series[1][dataPointIndex] + '%</strong>' +
                    '</div>' +
                    '</div>'
                )
              } else {
                return (
                    '<div style="font-size: 13px">' +
                    '<div style="background-color: #F3F7FF; padding: 10px">' + moment(categories[dataPointIndex], 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM') + '</div>' +
                    '<div style="padding: 10px">' +
                    '<div style="background-color: #5B5E8B; display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 3px;"></div> ' +
                    utilText + '<strong>' + series[0][dataPointIndex] + '%</strong>' +
                    '</div>' +
                    '</div>'
                )
              }
            },
            // y: {
            //   formatter: function (val) {
            //     return val + "%"
            //   },
            //   title: {
            //     formatter: () => 'Утилизация:',
            //   },
            // }
          }
      }
    },
    dateCounter() {
      const arrayValues = this.resourcesUtilizationSelectedData.map(item => item.value);
      const prevArrayValues = this.resourcesUtilizationPreviousData.map(item => item.value);
      return [
        {
          name: moment(this.filters.from, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM') + " - " + moment(this.filters.to, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM'),
          data: arrayValues
        },
        {
          name: moment(this.prevFrom, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM') + " - " + moment(this.prevTo, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM'),
          data: prevArrayValues
        }
      ];
    }
  },
  methods: {
    changeWorkingHours(dataHours) {
      this.filters.workingHours = dataHours;
      this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters);
    },
    setPeriod(val) {
      if (val.id === 1) {
        this.filters.from = moment(new Date()).add(-6, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 2) {
        this.filters.from = moment(new Date()).add(-14, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 3) {
        this.filters.from = moment(new Date()).add(-29, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 4) {
        this.filters.from = moment(new Date()).startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 5) {
        this.filters.from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
      } else if (val.id === 6) {
        this.$bvModal.show('modal-edit-period-date');
      }
      if (val && val.id !== undefined && val.id !== 6) {
        this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters)
            .then(res => {
              this.prevFrom = res.previousData[0].date;
              this.prevTo = res.previousData[res.previousData.length - 1].date;
            });
      }
    },
    changePeriodDate(newDate) {
      this.filters.from = newDate.from;
      this.filters.to = newDate.to;
      this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters)
          .then(res => {
            this.prevFrom = res.previousData[0].date;
            this.prevTo = res.previousData[res.previousData.length - 1].date;
          });
    },
    onChangeDatePicker() {
      this.$store.dispatch(GET_RESOURCES_UTILIZATION_REPORT, this.filters).then(res => {
        this.prevFrom = res.previousData[0].date;
        this.prevTo = res.previousData[res.previousData.length - 1].date;
      });
    }
  }
}
</script>

<style scoped>

</style>
