import { render, staticRenderFns } from "./MeetingRoomsUtilization.vue?vue&type=template&id=2f5c1aff&scoped=true&"
import script from "./MeetingRoomsUtilization.vue?vue&type=script&lang=js&"
export * from "./MeetingRoomsUtilization.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5c1aff",
  null
  
)

export default component.exports